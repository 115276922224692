@font-face {
	font-family: Montserratarm;
	src: url('/fonts/Montserratarm-Light.woff2') format('woff2'), url('/fonts/Montserratarm-Light.woff') format('woff'),
		url('/fonts/Montserratarm-Light.otf') format('otf');
	font-display: swap;
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: Montserratarm;
	src: url('/fonts/Montserratarm-Regular.woff2') format('woff2'), url('/fonts/Montserratarm-Regular.woff') format('woff'),
		url('/fonts/Montserratarm-Regular.otf') format('otf');
	font-display: swap;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: Montserratarm;
	src: url('/fonts/Montserratarm-Medium.woff2') format('woff2'), url('/fonts/Montserratarm-Medium.woff') format('woff'),
		url('/fonts/Montserratarm-Medium.otf') format('otf');
	font-display: swap;
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: Montserratarm;
	src: url('/fonts/Montserratarm-SemiBold.woff2') format('woff2'), url('/fonts/Montserratarm-SemiBold.woff') format('woff'),
		url('/fonts/Montserratarm-SemiBold.otf') format('otf');
	font-display: swap;
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: Montserratarm;
	src: url('/fonts/Montserratarm-Bold.woff2') format('woff2'), url('/fonts/Montserratarm-Bold.woff') format('woff'),
		url('/fonts/Montserratarm-Bold.otf') format('otf');
	font-display: swap;
	font-style: normal;
	font-weight: 700;
}
