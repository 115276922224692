@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?t4d8dl');
  src:  url('fonts/icomoon.eot?t4d8dl#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?t4d8dl') format('woff2'),
    url('fonts/icomoon.ttf?t4d8dl') format('truetype'),
    url('fonts/icomoon.woff?t4d8dl') format('woff'),
    url('fonts/icomoon.svg?t4d8dl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="aab-"], [class*=" aab-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.aab-arrow-dropdown:before {
  content: "\e91b";
}
.aab-services-5:before {
  content: "\e918";
}
.aab-services-4:before {
  content: "\e919";
}
.aab-services-3:before {
  content: "\e91a";
}
.aab-arrow:before {
  content: "\e903";
}
.aab-about-us-3:before {
  content: "\e900";
}
.aab-about-us-1:before {
  content: "\e901";
}
.aab-about-us-2:before {
  content: "\e902";
}
.aab-facebook:before {
  content: "\e904";
}
.aab-homepage-1:before {
  content: "\e905";
}
.aab-homepage_2_icon:before {
  content: "\e906";
}
.aab-homepage-3:before {
  content: "\e907";
}
.aab-instagram:before {
  content: "\e908";
}
.aab-linkedin:before {
  content: "\e909";
}
.aab-services-1:before {
  content: "\e90a";
}
.aab-services-2:before {
  content: "\e90b";
}
.aab-solutions-1:before {
  content: "\e90c";
}
.aab-solutions-2:before {
  content: "\e90d";
}
.aab-solutions-3:before {
  content: "\e90e";
}
.aab-solutions-4:before {
  content: "\e90f";
}
.aab-solutions-5:before {
  content: "\e910";
}
.aab-solutions-6:before {
  content: "\e911";
}
.aab-solutions-7:before {
  content: "\e912";
}
.aab-solutions-8:before {
  content: "\e913";
}
.aab-solutions-9:before {
  content: "\e914";
}
.aab-solutions-10:before {
  content: "\e915";
}
.aab-solutions-11:before {
  content: "\e916";
}
.aab-solutions-12:before {
  content: "\e917";
}
